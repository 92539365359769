<template>
  <div style="height: calc(100vh - 120px);">
    <listToolbar :toolbar="toolbar" @addTap="addTap"/>
    <listTable
      :list="list"
      :loading="loading"
      :pageShow="false"
      :columns="columns" />
    <el-drawer v-model="formDrawerVisible" size="30%" :title="typeForm === 'add'? '添加' : '修改'">
      <div v-if="formDrawerVisible" style="padding-left: 16px; padding-right: 16px;">
        <Form :columns="addColumns" :type="typeForm" @submit="submit" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import listToolbar from '@/components/system/listToolbar.vue'
import listTable from '@/components/system/listTable.vue'
import { schoolDel, schoolList, schoolSubmit } from '@/apis/common'
import Form from '@/components/system/form.vue'

export default {
  name: 'classify',
  components: { Form, listTable, listToolbar },
  data() {
    return {
      info: JSON.parse(sessionStorage.getItem('user')) || {},
      toolbar: [
        {type: 'button', label: '添加', def: 'primary',key: 'add'}
      ],
      loading: false,
      columns: [
        {label: '分类ID', prop: 'id', width: 100, type: 'text'},
        {label: '分类名字', prop: 'name', width: 200, type: 'text'},
        {label: '操作', width: 100, type: 'but', arr: [
           {type: 'primary', icon: 'el-icon-edit', label: '修改', onClick: (row) => this.modify(row)},
           {type: 'danger', icon: 'el-icon-delete', label: '删除', onClick: (row) => this.del(row)},
        ]}
      ],
      list: [],
      typeForm: 'add',
      addColumns: [
        {label: '分类名字', prop: 'name', type: 'input',value: ''}
      ],
      formDrawerVisible: false,
      addFormData: {}
    }
  },
  mounted() {
    this.getSchoolList()
  },
  methods: {
    del(e) {
      this.$confirm('是否删除该分类?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        schoolDel({
          admin_id: this.info.id,
          agency_id: this.info.agency_school_id,
          type_id: e.id
        }).then(res => {
          this.$message.success('操作成功')
          this.getSchoolList()
        })
      }).catch(() => {})
    },
    // 修改
    modify(row) {
      this.addFormData = row
      this.typeForm = 'edit'
      this.addColumns.forEach(item => {
        item.value = row[item.prop]
      })
      this.formDrawerVisible = true
    },
    submit(e,n) {
      schoolSubmit({
        admin_id: this.info.id,
        agency_id: this.info.agency_school_id,
        type_id: n === 'edit' ? this.addFormData.id : '',
        name: e.name
      }).then(res => {
        this.$message.success('操作成功')
        this.getSchoolList()
        this.formDrawerVisible = false
      })
    },
    addTap() {
      this.addColumns.forEach(item => {
        item.value = ''
      })
      this.formDrawerVisible = true
      this.typeForm = 'add'
    },
    getSchoolList() {
      this.loading = true
      schoolList({
        admin_id: this.info.id,
        agency_id: this.info.agency_school_id
      }).then(res => {
        this.loading = false
        this.list = res.data.data
      })
    },
  }
}
</script>

<style scoped>

</style>
